import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const GoogleAnalytics = props => {
  const { ga } = props;

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${ga}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${ga}' , { 'anonymize_ip': true });
          `}
      </script>
    </Helmet>
  );
};

GoogleAnalytics.propTypes = {
  ga: PropTypes.string,
};

export default GoogleAnalytics;
