export { appInit, oauthInfo, goToPanel, appLoading } from './app';

export {
  showInitialLoader,
  hideInitialLoader,
  showModal,
  hideModal,
  toggleSidebar,
  toggleModal,
} from './ui';

export {
  authInit,
  signinInit,
  signinClearError,
  signUpInit,
  signupClearError,
  checkAuthState,
  logout,
  authSuccess,
  setRedirect,
  recoveryPassword,
  recoveryPasswordClearError,
  oauthSendToken,
  checkAuth,
  resetPassword,
  resetPasswordClearError,
  resetChangePassword,
  resetChangePasswordClearError,
} from './auth';

export {
  getUserInfo,
  setUserInfo,
  userActivation,
  getUserIsLogged,
  userActivationClearError,
  changePassword,
  changePasswordClearError,
  changePasswordClear,
  setUserInfoSuccess,
  setUserInfoClear,
} from './user';

export { getViewer } from './viewer';

export {
  getAllPromo,
  getHighlightsPromo,
  getPromoViewer,
  cancelGetPromo,
  getPromoViewerReset,
  getPromoInfo,
  getCmsIntro,
  getGames,
  getGame,
  setGameRetry,
  getPromoTag,
  getPromoTagViewer,
  reset404error,
} from './promo';

export {
  getCode,
  getLink,
  getCodeCleanError,
  cleanCodeFunction,
  sendGameResult,
  getCodeAfterGame,
  getIw,
  getIwCleanError,
  cleanIwDataFunction,
  saveGameResult,
  saveGameResultCleanError,
  checkFormCompiled,
} from './game';

export {
  getPartnerList
} from './partners';

export { sendLogError } from './error';

export { bootApp } from './bootApp';
