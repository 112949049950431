import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Ui';
import { useParams, Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import ErrorInModal from '../../components/ErrorInModal';
import { Modal, Flex, Col, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';

const SingleAdvantage = props => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [partner, setPartner] = useState();
  const [modalError, setModalError] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(null);
  const [requestLoading, setLoadingRequest] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [modalRequestOpened, setModalRequestOpened] = useState(false);

  const partners = useSelector(state => state.partners.partnerList);
  const vouchers = useSelector(state => state.user.user.vouchers);
  const userPoints = useSelector(state => state.user.user.points);

  useEffect(() => {
    if (id && partners) {
      const newPartner = partners.find(partner => partner.id === Number(id));

      setPartner(newPartner);
    }
  }, [partners, id]);

  useEffect(() => {
    if (partner && vouchers) {
      const existCoupon = vouchers.find(voucher => voucher.partner_id === partner.id);

      if (existCoupon) {
        setHasCoupon(existCoupon);
      }
    }
  }, [partner, vouchers]);

  const requestCouponHandler = code => {
    let endpoint = `partners/${partner.id}/request`;
    setModalRequestOpened(false);
    setLoadingRequest(true);
    customaxios
      .post(endpoint, { codiceIniziativa: code })
      .then(result => {
        setLoadingRequest(false);
        dispatch(actionCreators.getUserInfo());
        setRequestCompleted(true);
      })
      .catch(error => {
        setLoadingRequest(false);
        setModalError(error.response.data.error);
      });
  };

  const closeModalError = () => {
    setModalError(null);
  };
  const closeModalRequest = () => {
    setModalRequestOpened(false);
  };

  return (
    <>
      <Fade>
        <Flex row={15} className='mt-20'>
          {partner && (
            <>
              <Col width={40}>
                <img src={partner.main_image} alt='' />
              </Col>
              <Col width={60}>
                <BoxTravel>
                  <Flex justify='space-between'>
                    <div className='points-box'>
                      <Text upper primary bold size={12}>
                        Punti
                      </Text>
                      <Text light primary size={30}>
                        {partner.value}
                      </Text>
                    </div>
                    <div className='points-box an'>
                      <Text upper bold size={12}>
                        Articolo numero
                      </Text>
                      <Text light size={30}>
                        {partner.under_value}
                      </Text>
                    </div>
                  </Flex>
                  <Text light size={48} className='mb-20 mt-30'>
                    {partner.title}
                  </Text>
                  <Text light style={{ marginBottom: 50 }}>
                    {partner.description}
                  </Text>
                  {!requestCompleted && (
                    <Flex justify='space-between'>
                      <Link to='/'>
                        <Button arrowLeft>Indietro</Button>
                      </Link>

                      {userPoints?.points_confirmed >= partner.value ? (
                        <Button
                          loading={requestLoading}
                          onClick={() => setModalRequestOpened(true)}
                        >
                          Richiedi
                        </Button>
                      ) : (
                        <Text primary upper align='right'>
                          {`Non hai ancora abbastanza punti
                          <br />
                          per richiedere questo premio`}
                        </Text>
                      )}
                    </Flex>
                  )}
                </BoxTravel>

                {hasCoupon && requestCompleted && (
                  <CouponBox>
                    <Text white upper className='mb-20'>
                      Ecco il tuo codice
                    </Text>
                    <CouponCode>
                      <Text white light size={32} align='center'>
                        {hasCoupon.code}
                      </Text>
                    </CouponCode>

                    <Text bold upper white className='mb-10 mt-20'>
                      Come usarlo
                    </Text>
                    <Flex align='flex-end' justify='space-between'>
                      <Text>{partner.howto}</Text>
                      <a href={partner.url} style={{ marginLeft: 20 }}>
                        <Button white>Sito</Button>
                      </a>
                    </Flex>
                  </CouponBox>
                )}
              </Col>
            </>
          )}
        </Flex>
      </Fade>
      <Modal show={modalRequestOpened} close={closeModalRequest}>
        <Text as='p' size={18} bold margin='3px 0 25px 0' align='left'>
          Confermi di voler richiedere il premio selezionato?
        </Text>

        <Button
          active
          style={{ display: 'block', textAlign: 'left', marginLeft: 'auto' }}
          loading={requestLoading}
          disabled={requestLoading}
          onClick={() => requestCouponHandler(partner.static_code)}
        >
          CONFERMA
        </Button>
      </Modal>
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </>
  );
};

const BoxTravel = styled.div`
  padding-top: 20px;
  margin-bottom: 30px;

  .points-box {
    border: 1px solid ${props => props.theme.primary};
    padding: 10px;
    min-width: 170px;

    &.an {
      border: none;
    }
  }
`;

const CouponBox = styled.div`
  background-color: #a3aec5;
  padding: 15px;
  margin-bottom: 30px;
`;

const CouponCode = styled.div`
  flex: 0 0 100%;
  border: 1px solid #fff;
  padding: 15px;
  margin-left: 0;

  ${respondTo.md`
    flex: 0 0 455px;
  `}
`;

export default SingleAdvantage;
