import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from '../Ui';
import styled from 'styled-components';
import { KeyboardArrowUp } from '@styled-icons/material';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const TopBar = styled.div`
  text-align: center;
  padding: 10px 0;

  img {
    max-width: ${props => props.theme.footer_logo_size}px;
  }
`;

const BottomBar = styled.div`
  background-color: #f1f3f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 15px;

  img {
    max-width: 20px;
    height: auto;
  }

  p {
    display: inline;
  }

  a {
    color: #a3aec5;
  }
`;

const IconUp = styled(KeyboardArrowUp)`
  cursor: pointer;
  color: ${props => props.theme.primary};
  flex: 0 0 30px;
  height: 30px;
  border: 2px solid ${props => props.theme.primary};
`;

const Footer = props => {
  const { footercontent } = props;

  // Get info from CMS section
  const footerlogo = footercontent[0].contents.find(el => el.name === 'footer_logo');
  const footertext = footercontent[0].contents.find(el => el.name === 'footer_text');

  return (
    <Wrapper style={{ backgroundColor: '#fff' }}>
      <BottomBar>
        <span />
        <div style={{ fontSize: 9, fontWeight: 700, textTransform: 'uppercase', color: '#A3AEC5' }}>
          {parse(
            `${footertext?.i18l.content} | <a href='${
              process.env.PUBLIC_URL + '/pdf/privacy-policy.pdf'
            }' target='_blank' rel='noopener noreferrer' style="text-decoration: underline">Informativa sulla privacy</a> |`,
          )}{' '}
          <Link to='/cookie-policy' style={{ textDecoration: 'underline' }}>
            Cookie Policy
          </Link>
        </div>

        <IconUp size={30} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
      </BottomBar>
      <TopBar>
        <img src={footerlogo?.img} alt='' />
      </TopBar>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
