import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import NotFound from '../views/NotFound';
import SingleAdvantage from '../views/SingleAdvantage/SingleAdvantage';
import HowItWorks from '../views/HowItWorks/HowItWorks';
import Destinations from '../views/Destinations/Destinations';
import FirstAccess from '../views/FirstAccess/FirstAccess';
import AccountDeleted from '../views/AccountDeleted/AccountDeleted';
import Practices from '../views/Practices/Practices';
import MemberGetMember from '../views/MemberGetMember/MemberGetMember';
import CookiePolicy from '../views/CookiePolicy/CookiePolicy';

const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const Profile = React.lazy(() => import('../views/Profile'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  const [inIframe, setInIframe] = useState(false);
  const [checkInIframeEnd, setCheckInIframeEnd] = useState(false);

  useEffect(() => {
    if (window.self !== window.top) {
      setInIframe(true);
    }
    setCheckInIframeEnd(true);
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          checkInIframeEnd && (
            <>
              {inIframe ? (
                <NotFound type='frame' error='frame' />
              ) : (
                (window.location = 'https://www.easybook.it/')
              )}
            </>
          )
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin } = props;

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path='/'
            exact
            component={Initiative}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/first-access'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={FirstAccess}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/account-deleted'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={AccountDeleted}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/howitworks'
            exact
            component={HowItWorks}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/viaggi/:id'
            exact
            component={SingleAdvantage}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/practices'
            exact
            component={Practices}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/mgm'
            exact
            component={MemberGetMember}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <Route
            path='/cookie-policy'
            exact
            render={route => <BaseLayout cmp={CookiePolicy} {...route} />}
          />
          <Route path='/login/:token' render={route => <AuthLayout cmp={Login} {...route} />} />
          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
  };
};

export default connect(mapStateToProps)(Routes);
