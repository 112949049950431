import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from '../../components/Ui';
import customAxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../store/actions';
import { useState } from 'react';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from '@react-pdf/renderer';
import { respondTo } from '../../theme/mixin';
import { Download } from '@styled-icons/boxicons-regular/Download';
import pdfHero from '../../assets/images/pdf-hero.png';
import { useEffect } from 'react';

const MemberWrapper = styled.div`
  margin-top: 30px;

  .memberKpi {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    ${respondTo.sm`
      flex-wrap: no-wrap;
    `}

    .kpiWrapper {
      width: 100%;
      margin-bottom: 30px;

      ${respondTo.sm`
        width: 20%;
        margin-bottom: 0;
      `}

      h5 {
        font-size: 18px;
        color: ${props => props.theme.primary};
        text-transform: uppercase;
      }

      .kpiContent {
        margin-top: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 32px;
        padding: 10px 0;
        text-align: center;
        margin-right: 30px;
      }
    }
  }

  h1 {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 36px;
    color: ${props => props.theme.primary};
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 80px;

    th,
    td {
      padding: 5px;
      text-align: center;
    }

    th {
      color: #fff;
      background-color: ${props => props.theme.primary};
      border-right: 1px solid #fff;
    }
  }

  .modalMgm {
    text-align: center;

    h4 {
      font-size: 24px;
    }

    strong {
      font-size: 28px;
      display: block;
      margin: 20px 0;
    }

    .downloadMgm {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        padding-left: 5px;
      }
    }
  }
`;

const styles = StyleSheet.create({
  page: { padding: 40 },
  margin: {
    fontFamily: 'Helvetica',
    marginBottom: 30,
    fontSize: 15,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  blue: {
    color: 'blue',
  },
});

const MyDoc = props => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View>
        <Image style={styles.margin} src={pdfHero} />
        <Text style={styles.margin}>Gentile cliente,</Text>
        <Text style={styles.margin}>
          ecco il tuo codice da utilizzare al momento della registrazione al programma fedeltà "You
          and Sun" di Alpitour S.p.A.:
        </Text>
        <Text style={[styles.margin, styles.bold]}>{props.code}</Text>
        <Text style={styles.margin}>
          <Text style={[styles.margin, styles.bold]}>Cosa fare per utilizzarlo?</Text> Accedi con le
          tue credenziali a <Text style={styles.blue}>https://youandsun.it</Text> (nel caso non le
          avessi potrai generarle al momento dell’accesso), ed inserisci nell’apposito campo il
          codice sopra comunicato.
        </Text>
        <Text style={styles.margin}>
          Super notizia: <Text style={styles.bold}>100 punti extra in omaggio per te!</Text>
        </Text>
      </View>
    </Page>
  </Document>
);

const MemberGetMember = () => {
  const dispatch = useDispatch();
  const codes = useSelector(state => state.user?.user.mgm_invitation_codes);

  const [loading, setLoading] = useState(false);
  const [currentCode, setCurrentCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const generateNewCodeHandler = () => {
    setLoading(true);
    customAxios.post('/auth/mgmb2bgenerate').then(res => {
      setCurrentCode(res.data.code);
      setModalOpen(true);
      dispatch(getUserInfo());
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!modalOpen) {
      setTimeout(() => {
        setCurrentCode('');
      }, 1000);
    }
  }, [modalOpen]);

  return (
    <MemberWrapper>
      <div className='memberKpi'>
        <div className='kpiWrapper'>
          <h5>Totali</h5>
          <div className='kpiContent'>{codes && codes.length}</div>
        </div>
        <div className='kpiWrapper'>
          <h5>Attivati</h5>
          <div className='kpiContent'>{codes && codes.filter(c => c.used).length}</div>
        </div>
        <div className='kpiWrapper'>
          <h5>Pending</h5>
          <div className='kpiContent'>{codes && codes.filter(c => !c.used).length}</div>
        </div>
      </div>

      <h1>I tuoi codici MGM</h1>
      {codes && codes.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>AZIONI</th>
                <th>CODICE</th>
                <th>DATA EROGAZIONE</th>
                <th>STATO CODICE</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((code, codeIndex) => {
                return (
                  <tr key={codeIndex}>
                    <td>
                      {code.used ? (
                        '-'
                      ) : (
                        <PDFDownloadLink
                          document={<MyDoc code={code.code} />}
                          fileName='codice-mgm.pdf'
                        >
                          {({ blob, url, loading, error }) => <Download size={20} />}
                        </PDFDownloadLink>
                      )}
                    </td>
                    <td>{code.code}</td>
                    <td>{code.created_at}</td>
                    <td>{code.used ? 'ATTIVATO' : 'DA ATTIVARE'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        'Nessun codice generato'
      )}

      <Modal show={modalOpen} close={() => setModalOpen(false)}>
        <div className='modalMgm'>
          <h4>Codice MGM generato</h4>
          <strong>{currentCode}</strong>
          <PDFDownloadLink document={<MyDoc code={currentCode} />} fileName='codice-mgm.pdf'>
            {({ blob, url, loading, error }) => (
              <div className='downloadMgm'>
                <Download size={24} /> <span>Download PDF</span>
              </div>
            )}
          </PDFDownloadLink>
        </div>
      </Modal>
    </MemberWrapper>
  );
};

export default MemberGetMember;
