import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@styled-icons/material';

const ButtonWrap = styled.button`
  border: 2px solid
    ${props =>
      props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => (props.loading || props.disabled ? 'default' : 'pointer')};
  outline: none;
  color: ${props =>
    props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  padding: 0 10px;
  text-align: left;
  background-color: transparent;
  transition: all 0.3s;
  height: 30px;
  font-size: 16px;
  font-weight: 700;
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  opacity: ${props => (props.loading || props.disabled ? '.5' : '1')};
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  min-width: 170px;
  line-height: 1;

  svg {
    position: relative;
    right: -10px;
    top: -1px;
  }

  &:hover {
    color: ${props => (props.white ? props.theme.primary : '#fff')};
    background-color: ${props =>
      props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  }
`;

const Button = props => {
  return (
    <ButtonWrap {...props}>
      {props.loading ? (
        <Loader show={true} inBtn white={props.white ? true : false} />
      ) : (
        <>
          {props.children}
          {props.arrowDown ? (
            <KeyboardArrowDown size={25} />
          ) : props.arrowLeft ? (
            <ChevronLeft size={25} />
          ) : (
            <ChevronRight size={25} />
          )}
        </>
      )}
    </ButtonWrap>
  );
};

Button.defaultProps = {
  upper: true,
  loading: false,
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
  loading: PropTypes.bool,
  white: PropTypes.bool,
};

export default Button;
