import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo, Text } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import { respondTo } from '../../theme/mixin';
import profileImage from '../../assets/images/profile-icon.svg';
import { Close } from '@styled-icons/material';
import { Menu } from 'styled-icons/boxicons-regular';

const HeaderSection = styled.header`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 15px;
  z-index: 9;
  width: calc(100% - 30px);

  ${respondTo.sm`
    margin-left: 0;
    width: 100%;
    position: static;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${props => props.theme.header_mobile_height + 'px'};
  background-color: #fff;
  margin: 0 -15px;

  ${respondTo.sm`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
  `}
`;

const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 83px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 5px 15px #00000026;

  ${respondTo.sm`
    width: auto;
		display: flex;
    top: 0;
    position: static;
    border-top: none;
    box-shadow: none;
	`}
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;

  .profile-name {
    margin-left: 10px;

    span {
      line-height: 1;
    }
  }

  .profile-points {
    margin: 0 10px;
    padding: 0 10px;
    border-left: 1px solid #a3aec5;
    border-right: 1px solid #a3aec5;

    small {
      line-height: 1;
    }
  }

  .profile-exit {
    cursor: pointer;
    display: none;

    ${respondTo.sm`
		display: block;
  `}
  }
`;

const Header = props => {
  const { logo, logout, isLogged, multipromo, i18l } = props;

  const games = useSelector(state => state.game.games);
  const userInfo = useSelector(state => state.user.user.userinfo);
  const points = useSelector(state => state.user.user.points);

  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (games.length === 1) {
      setIdGame(games[0].id);
    }
  }, [games]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer>
          <ProfileBox>
            <img src={profileImage} alt='' />
            <div className='profile-name'>
              <Text size={18}>{userInfo?.firstname}</Text>
              <Text size={18}>{userInfo?.lastname}</Text>
            </div>
            <div className='profile-points'>
              <Text as='small' size={10} upper bold>
                {'punti<br/>confermati'}
              </Text>
              <Text primary size={18}>
                {points?.points_confirmed || 0}
              </Text>
            </div>
            <div className='profile-exit' onClick={logout}>
              <Text size={12} bold primary>
                ESCI
              </Text>
            </div>
          </ProfileBox>

          <MenuContainerDesk>
            <Logo logo={logo} />
          </MenuContainerDesk>

          <NavContainer isOpen={isMenuOpen}>
            <NavigationItems
              isLogged={isLogged}
              multipromo={multipromo}
              i18l={i18l}
              idGame={idGame}
              logout={logout}
            />
          </NavContainer>
          <MenuContainerMobile onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <Close size={45} /> : <Menu size={45} />}
          </MenuContainerMobile>
        </HeaderContainer>
      </Wrapper>
    </HeaderSection>
  );
};

const MenuContainerMobile = styled.div`
  display: flex;

  ${respondTo.sm`
		display: none;
	`}
`;

const MenuContainerDesk = styled.div`
  display: none;

  ${respondTo.sm`
		display: block;
  `}
`;

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
