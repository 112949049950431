import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parse from 'html-react-parser';

const TextContainer = styled.span`
  display: block;
  margin: ${props => props.margin};
  font-size: ${props => props.size}px;
  font-weight: ${props => (props.bold ? '700' : props.light ? '300' : '400')};
  text-align: ${props => props.align};
  color: ${props =>
    props.color
      ? props.color
      : props.primary
      ? props.theme.primary
      : props.white
      ? '#fff'
      : 'inherit'};
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};

  ul {
    padding-left: 20px;
  }

  a {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  }
`;

const Text = props => {
  return (
    <TextContainer {...props}>
      {typeof props.children === 'number' ? props.children : parse(props.children || '')}
    </TextContainer>
  );
};

Text.defaultProps = {
  size: 16,
  align: 'left',
};

Text.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  align: PropTypes.string,
  white: PropTypes.bool,
  bold: PropTypes.bool,
  light: PropTypes.bool,
  upper: PropTypes.bool,
};

export default Text;
