import React, { useRef, useEffect } from 'react';
import { Wrapper } from '../../components/Ui';

const CookiePolicy = () => {
  const scriptRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://consent.cookiebot.com/8f436ecd-559a-4ad4-80fc-ba7718b8cfc7/cd.js';
    script.async = true;

    scriptRef.current.appendChild(script);
  }, []);

  return (
    <Wrapper style={{ marginTop: 30, marginBottom: 50 }}>
      <h1>COOKIE POLICY</h1>
      <h3>
        COOKIE POLICY - Informativa ex art 13 del Regolamento Generale per la Protezione dei Dati UE
        2016/679 (GDPR)
      </h3>{' '}
      La presente informativa costituisce l’estensione delle informazioni sull’utilizzo di cookie e
      degli altri identificatori tecnici fornite all’interessato per tramite dell’informativa
      semplificata presente sul banner mostrato al momento dell’accesso al presente sito web.
      <br />
      Questa pagina è indirizzata agli utenti che consultano il sito web accessibile per via
      telematica al seguente indirizzo: www.youandsun.it (il <strong>“Sito”</strong>) Il Titolare
      del trattamento è Alpitour S.p.A. con sede in Via Lugaro, 15 - 10126 Torino (di seguito “
      <strong>Società</strong>” “<strong>Titolare</strong>”) Il Responsabile per la protezione dei
      dati personali (di seguito, “DPO”) è raggiungibile al seguente recapito:{' '}
      <a href='mailto:DPO@alpitourworld.it' style={{ color: '#004B8C' }}>
        DPO@alpitourworld.it
      </a>
      .<br />
      <h4>
        1. <strong>Definizioni, caratteristiche e applicazione della normativa</strong>
      </h4>
      I cookie sono piccoli file di testo che i siti visitati dall'utente inviano e registrano sul
      Suo dispositivo terminale (computer o dispositivi mobili come smartphone e tablet), per essere
      poi ritrasmessi agli stessi siti alla successiva visita.
      <br />
      <br />
      Proprio grazie ai cookie un sito ricorda le azioni e preferenze dell'utente (come, ad esempio,
      i dati di login, la lingua prescelta, le dimensioni dei caratteri, altre impostazioni di
      visualizzazione, ecc.) in modo che non debbano essere indicate nuovamente quando l'utente
      torni a visitare detto sito o navighi da una pagina all'altra di esso. I cookie, quindi, sono
      usati per eseguire autenticazioni informatiche, monitoraggio di sessioni, agevolazione della
      fruizione dei contenuti e memorizzazione di informazioni riguardanti le attività degli utenti
      che accedono ad un sito e possono contenere anche un codice identificativo unico che consente
      di tenere traccia della navigazione dell'utente all'interno del sito stesso per finalità
      statistiche o pubblicitarie.
      <br />
      <br />
      a. <strong>Classificazione dei Cookie</strong>
      <br />
      Nel corso della navigazione su un sito, oltre ai cookie archiviati direttamente dal sito
      visitato (c.d. “<strong>cookie di prime parti</strong>”), l'utente può ricevere sul suo
      computer anche cookie di siti o di web server diversi da quello che sta visitando (c.d. “
      <strong>cookie di terze parti</strong>").
      <br />
      <br />
      Alcune operazioni non potrebbero essere compiute senza l'uso dei cookie, che in certi casi
      sono quindi tecnicamente necessari per lo stesso funzionamento del sito. Esistono vari tipi di
      cookie, a seconda delle loro caratteristiche e funzioni, e questi possono rimanere nel
      computer dell'utente per periodi di tempo diversi: c.d. “<strong>cookie di sessione</strong>”
      o indicati anche come “<strong>cookie di navigazione</strong>”, che viene automaticamente
      cancellato alla chiusura del browser; c.d. “<strong>cookie permanenti</strong>”, che
      permangono sull'apparecchiatura dell'utente fino ad una scadenza prestabilita.
      <br />
      <br />
      Vi sono poi cookie, c.d. “<strong>cookie analytics</strong>” o “<strong>Statistiche</strong>”,
      utilizzati ai fini di valutazione in merito all’efficacia di un servizio della società
      dell’informazione fornito, per la progettazione di un sito web o per contribuire a misurarne
      il “traffico”, cioè il numero di visitatori anche eventualmente ripartiti per area geografica,
      fascia oraria della connessione o altre caratteristiche.
      <br />
      <br />
      In base alla normativa vigente in Italia, per l'utilizzo dei cookie non sempre è richiesto un
      espresso consenso dell'utente. In particolare, non richiedono tale consenso i c.d. "
      <strong>cookie tecnici</strong>", cioè quelli utilizzati al solo fine di effettuare la
      trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura
      strettamente necessaria per erogare un servizio esplicitamente richiesto dall'utente. Si
      tratta, in altre parole, di cookie indispensabili per il funzionamento del sito o necessari
      per eseguire attività richieste dall'utente, in assenza dei quali alcune operazioni non
      potrebbero essere compiute o sarebbero più complesse e/o meno sicure.
      <br />
      <br />
      Tra i cookie tecnici, che non richiedono un consenso espresso per il loro utilizzo, il Garante
      per la protezione dei dati personali italiano (cfr. Linee guida cookie e altri strumenti di
      tracciamento del 10 giugno 2021 e successivi chiarimenti, di seguito solo "
      <strong>Linee Guida</strong>") ricomprende anche:
      <br />
      <ul>
        <li>
          i cookie analytics qualora mediante il loro uso sia preclusa la possibilità di pervenire
          alla diretta individuazione dell’interessato (cd. “<strong>single out</strong>”),
          impedendo pertanto l’uso di cookie analytics che possano risultare identificatori diretti
          ed univoci. Inoltre, la loro struttura deve prevedere la possibilità di riferirli non ad
          uno ma a più dispositivi, in modo da creare una ragionevole incertezza sull’identità
          informatica del soggetto che lo riceve (solitamente attraverso il mascheramento di
          porzioni dell’indirizzo IP all’interno del cookie stesso così come indicato nelle Linee
          Guida). Infine, per essere identificati come tecnici, i cookie analytics vanno limitati
          alla sola produzione di statistiche aggregate e che vengano utilizzati relativamente ad un
          singolo sito/ applicazione mobile, in modo da non consentire il tracciamento della
          navigazione della persona che utilizza applicazioni diverse o naviga in siti web diversi;
        </li>
        <li>i cookie di navigazione o di sessione;</li>
      </ul>
      <br />
      Per i c.d. "<strong>cookie di profilazione</strong>", viceversa, cioè quelli volti a creare
      profili relativi all'utente e utilizzati al fine di modulare la fornitura del servizio in modo
      personalizzato al di là di quanto strettamente necessario all’erogazione del servizio o di
      inviare messaggi pubblicitari mirati in linea con le preferenze manifestate dallo stesso
      nell'ambito della navigazione in rete e/o di effettuare analisi e monitoraggio del
      comportamento dei visitatori, è richiesto un preventivo consenso dell'utente.
      <br />
      <br />
      <strong>2. Tipologie di cookie utilizzate dal Sito</strong>
      <br />
      Il Titolare ha classificato i cookie in uso in base al loro tipo di utilizzo come segue.
      Discendendo anche per i terzi fornitori di cookie l’obbligo di rispettare la normativa in
      materia, rimandiamo al link delle pagine web dei siti della terza parte, nelle quali l’utente
      potrà trovare i moduli di raccolta del consenso ai cookie e le loro relative informative.
      <br />
      Questo sito web utilizza i cookie.
      <br />
      Su questo sito usiamo cookie tecnici, statistici e, previo tuo consenso, di profilazione
      nostri e di terze parti. Cliccando "Accetta tutti i cookie" acconsenti di ricevere tutti i
      cookie del nostro sito; cliccando su "Dettagli" puoi avere maggiori informazioni sui singoli
      cookie di ogni categoria. La chiusura del banner mediante selezione dell’apposito comando “X”
      comporta il permanere delle impostazioni di default, e dunque la continuazione della
      navigazione con i cookie tecnici. La casella dei cookie statistici è già selezionata poiché,
      non permettendo la diretta individuazione dell’interessato (cd. single out), i relativi cookie
      sono equiparati ai tecnici, ma puoi in ogni momento impedirne l’archiviazione deselezionando
      la relativa casella. Se vuoi maggiori informazioni sul funzionamento dei cookie attivi sul
      sito{' '}
      <a href='https://www.alpitour.it/legal/privacy-policy.html' style={{ color: '#004B8C' }}>
        clicca qui
      </a>
      .
      <br />
      In qualsiasi momento è possibile modificare o revocare il proprio consenso dalla{' '}
      <a href='https://www.alpitour.it/legal/privacy-policy.html' style={{ color: '#004B8C' }}>
        cookie policy
      </a>{' '}
      sul nostro sito Web attraverso il comando “Modifica consenso”.
      <br />
      Il tuo consenso si applica ai seguenti siti web: www.youandsun.it
      <br />
      <br />
      <div id='CookieDeclaration' ref={scriptRef}></div>
      <br />
      <h4>3. Le vostre decisioni in materia di cookie</h4>
      Potete selezionare una funzionalità secondo la quale il vostro computer vi avviserà
      ogniqualvolta un cookie viene impostato, oppure potete decidere di disattivare tutti i cookie.
      Potete selezionare tali funzionalità attraverso le impostazioni del vostro browser. Ogni
      browser è leggermente diverso, vi invitiamo quindi ad identificare nel menù “Aiuto” del vostro
      browser il modo più corretto per modificare le funzionalità relative ai cookie. Se decidete di
      disattivare i cookie, non avrete accesso a molte funzioni che rendono la vostra esperienza sul
      nostro sito più efficiente ed alcuni dei nostri servizi non funzioneranno correttamente.
      <br />
      <br />
      <h4>4. Diritti privacy ex artt. 15 e ss. del Regolamento</h4>
      Ai sensi degli articoli 15 e seguenti del Regolamento, ha il diritto di chiedere alla Società,
      in qualunque momento, l'accesso ai suoi Dati Personali, la rettifica o la cancellazione degli
      stessi, ha il diritto di opporsi al trattamento, ha diritto di richiedere la limitazione del
      trattamento nei casi previsti dall'art. 18 del Regolamento, nonché ha il diritto di ottenere
      in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che la
      riguardano, nei casi previsti dall'art. 20 del Regolamento. In qualsiasi momento potrà
      revocare ex art. 7 del Regolamento, il consenso già prestato, senza che ciò pregiudichi la
      liceità del trattamento effettuato anteriormente alla revoca del consenso.
      <br />
      <br />
      Le richieste vanno rivolte per iscritto al Titolare al seguente indirizzo{' '}
      <a href='privacy@alpitourworld.it' style={{ color: '#004B8C' }}>
        privacy@alpitourworld.it
      </a>{' '}
      ovvero al DPO (Data Protection Officer) al seguente indirizzo{' '}
      <a href='DPO@alpitourworld.it' style={{ color: '#004B8C' }}>
        DPO@alpitourworld.it
      </a>
      .
      <br />
      <br />
      In ogni caso hai sempre diritto di proporre reclamo all'Autorità di Controllo competente
      (Garante per la Protezione dei Dati Personali), ai sensi dell'art. 77 del Regolamento, qualora
      ritenga che il trattamento dei tuoi Dati Personali sia contrario alla normativa in vigore.
    </Wrapper>
  );
};

export default CookiePolicy;
