import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button, Text } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import Footer from '../../components/Footer/Footer';

const AccountDeleted = props => {
  const userInfo = useSelector(state => state.user.user.userinfo);
  const footercontent = useSelector(state => state.cms.footer);

  useEffect(() => {
    if (userInfo && userInfo.expire_date_limit) {
      const today = new Date();

      if (Date.parse(today) < Date.parse(userInfo.expire_date_limit)) {
        props.history.push('/');
      }
    }
    if (userInfo && !userInfo.expire_date) {
      props.history.push('/');
    }
  }, [userInfo, props.history]);

  return (
    <>
      <Wrapper>
        <div className='sides-wrapper'>
          <div className='bg-image'>
            <img src={process.env.PUBLIC_URL + '/images/firstaccess-image.png'} alt='' />
          </div>

          <div className='right-side'>
            <Text>
              Il tuo accout è stato cancellato come da tua richiesta, non puoi più accedere a
              MelAWiaggio
            </Text>
            <a href='https://www.easybook.it/'>
              <Button>Torna a esaybook</Button>
            </a>
          </div>
        </div>
      </Wrapper>
      <Footer footercontent={footercontent} />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .bg-image {
    position: absolute;
    left: 15px;
    top: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    .single-consens {
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      color: #a3aec5;
      font-weight: 500;

      a {
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
    position: relative;
    height: 640px;

    ${respondTo.sm`
      flex-wrap: nowrap;
    `};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .right-side {
      width: 470px;
      background-color: #fff;
      padding: 15px 30px;
      margin-right: 15px;
      z-index: 1;

      span {
        margin-top: 15px;
      }

      button {
        margin: 20px 0;
      }
    }
  }
`;

export default AccountDeleted;
