import React from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button } from '../../components/Ui';
import Image from '../../assets/images/meccanica-image.png';
import Fade from '../../components/Fade/Fade';
import { useSelector } from 'react-redux';

const HowItWorks = () => {
  const howItWorksCms = useSelector(state => state.cms.howItWorks);

  const how_text = howItWorksCms[0].contents.find(e => e.name === 'how_it_works_text');

  return (
    <Fade>
      <Flex row={15}>
        <Col padding={15} width={40}>
          <Text light size={48}>
            {how_text.i18l.title}
          </Text>
          <Text>{how_text.i18l.content}</Text>
        </Col>
        <Col padding={15} className='md_mt-30' width={60}>
          <Text primary light size={30}>
            Assegnazione punti
          </Text>

          <TablePoints>
            <thead>
              <tr>
                <td>
                  <Text upper bold white size={12}>
                    Azione premiante
                  </Text>
                </td>
                <td>
                  <Text upper bold white size={12}>
                    Punti
                  </Text>
                </td>
                <td>
                  <Text upper bold white size={12}>
                    Condizione per l'assegnazione
                  </Text>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Prenotazione* viaggio CORTO RAGGIO
                  <br />
                  <i>(Italia, Mediterraneo, Europa)</i>
                </td>
                <td>
                  <Text primary>20</Text>
                </td>
                <td>
                  Pratica creata e confermata dal partecipante nel sistema di teleprenotazione
                  Easybook
                </td>
              </tr>
              <tr>
                <td>
                  Prenotazione* viaggio MEDIO RAGGIO
                  <br />
                  <i>
                    (Canarie, Mar Rosso/Egitto, Capo Verde. Giordania e Israele, Marocco, Asia
                    Centrale, Repubbliche Baltiche)
                  </i>
                </td>
                <td>
                  <Text primary>25</Text>
                </td>
                <td>
                  Pratica creata e confermata dal partecipante nel sistema di teleprenotazione
                  Easybook
                </td>
              </tr>
              <tr>
                <td>
                  Prenotazione* viaggio LUNGO RAGGIO
                  <br />
                  <i>
                    (Nord e Sud America, Caraibi, Oceano Indiano, Australia, Oriente, Africa,
                    Emirati Arabi)
                  </i>
                </td>
                <td>
                  <Text primary>30</Text>
                </td>
                <td>
                  Pratica creata e confermata dal partecipante nel sistema di teleprenotazione
                  Easybook
                </td>
              </tr>
            </tbody>
          </TablePoints>
          <Text bold size={12} style={{ marginTop: 20, width: '100%' }}>
            (*) Le prenotazioni a tariffa individuale effettuate nella piattaforma Easybook che
            consentono l’accumulo dei punti si riferiscono alle combinazioni di pratiche di
            soggiorno/tour/crociera + trasporto (Volo I.T.C. Charter/ Volo di Linea/Traghetto/Treno)
            della durata minima di 8 giorni/7 notti, con partenze dalla data di iscrizione del
            partecipante sino al 30/9/23.
          </Text>
        </Col>
      </Flex>

      <DownloadRegulation className='md_mb-30'>
        <div>
          <Text light primary size={48}>
            Regolamento completo
          </Text>
          <a
            href={process.env.PUBLIC_URL + '/pdf/regolamento.pdf'}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='mt-20'>Scarica</Button>
          </a>
        </div>
        <img src={Image} alt='' />
      </DownloadRegulation>
    </Fade>
  );
};

const TablePoints = styled.table`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;

  thead {
    td {
      padding: 10px 20px;
      background-color: ${props => props.theme.primary};
      border-left: 1px solid #fff;
    }
  }

  tbody {
    td {
      font-size: 15px;
      padding: 10px 20px;
      border-bottom: 1px solid #a3aec5;
    }
  }
`;

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default HowItWorks;
