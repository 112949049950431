import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../Ui';
import ErrorInModal from '../ErrorInModal';

const SessionExpired = props => {
  const { isExpired } = props;

  const closeModalHandler = () => {
    localStorage.removeItem = 'token';
    window.location = 'https://www.easybook.it/';
  };

  return (
    <Modal show={isExpired} close={closeModalHandler}>
      <ErrorInModal error='sessionexpired' fromTranslation />
    </Modal>
  );
};

SessionExpired.propTypes = {
  isExpired: PropTypes.bool,
};

export default SessionExpired;
