import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CookieBot from 'react-cookiebot';

import { Provider } from 'react-redux';
import store from './store';

import './i18n';

import { Loader } from './components/Ui';

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader initial show={true} />}>
      <App />
      <CookieBot domainGroupId='8f436ecd-559a-4ad4-80fc-ba7718b8cfc7' />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
