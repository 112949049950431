import React from 'react';
import { Logo } from '../../../components/Ui';
import Text from '../../../components/Ui/Text/Text';
import { useSelector } from 'react-redux';

function ErrorFrame() {
  const logo = useSelector(state => state.app.config.logo);
  const firstAccessError = useSelector(state => state.auth.errorFirstAccess);

  return (
    <div>
      {logo && <Logo logo={logo} />}
      {firstAccessError === 'Subscription out of date' ? (
        <Text upper bold>
          Data di iscrizione scaduta
        </Text>
      ) : (
        <>
          <Text upper bold>
            Sessione scaduta
          </Text>
          <Text>
            {`Per accedere cliccare su “Logout” ed effettuare nuovamente l’accesso con le credenziali
        personali.<br />Nel caso il problema sussistesse scrivere a
        <a href='mailto:melawiaggio@alpitourworld.it'>melawiaggio@alpitourworld.it</a>`}
          </Text>
        </>
      )}
    </div>
  );
}

export default ErrorFrame;
