import { createGlobalStyle } from 'styled-components';
import { respondTo } from '../../theme/mixin';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
    color: ${props => props.theme.text_primary};
  }
  body {
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    font-size: 100%;
    height: 100%;
    background-color: #fff;

    ${respondTo.sm`
      background-color: #EAF0F3;
    `}
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.secondary};
  }
  
  .slick-list > div {
    margin-left: 0;
  }
  
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .md_mb-10 {
    margin-bottom: 10px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .md_mb-20 {
    margin-bottom: 20px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .md_mb-30 {
    margin-bottom: 30px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }

  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .md_mt-10 {
    margin-top: 10px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-20 {
    margin-top: 20px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-30 {
    margin-top: 30px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
`;

export default GlobalStyle;
