import styled from 'styled-components';

const InputField = styled.input`
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : '#A3AEC5'};
  color: ${props => props.theme.primary};
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  width: ${props => (props.type === 'checkbox' ? '20px' : '100%')};
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};

  &::placeholder {
    color: #47545d59;
  }
`;

export default InputField;
