import React from 'react';
import Fade from '../../components/Fade/Fade';
import styled from 'styled-components';
import { Text, Button, Flex } from '../../components/Ui';
import { Link } from 'react-router-dom';

const Destinations = () => {
  return (
    <>
      <Fade>
        <Text light size={48} className='mt-20'>
          Destinazioni richiedibili
        </Text>

        <DestinationList className='mt-10'>
          <Text color='#A3AEC5' upper size={12}>
            Corto raggio
          </Text>
          <ul>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
          </ul>
        </DestinationList>

        <DestinationList className='mt-10'>
          <Text color='#A3AEC5' upper size={12}>
            Medio raggio
          </Text>
          <ul>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
          </ul>
        </DestinationList>

        <DestinationList className='mt-10'>
          <Text color='#A3AEC5' upper size={12}>
            Lungo raggio
          </Text>
          <ul>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
            <li>
              <Text bold>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
              </Text>
            </li>
          </ul>
        </DestinationList>

        <Flex justify='center' className='mb-30'>
          <Link to='/'>
            <Button arrowLeft>Indietro</Button>
          </Link>
        </Flex>
      </Fade>
    </>
  );
};

const DestinationList = styled.div`
  margin-bottom: 60px;

  ul {
    list-style-type: none;
    border-top: 1px solid #a3aec5;
    margin-top: 5px;

    li {
      border-bottom: 1px solid #a3aec5;
      padding: 20px 0;
    }
  }
`;

export default Destinations;
