import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { respondTo } from '../../../../theme/mixin';

const LinkElem = styled(NavLink)`
  display: block;
  padding: 15px 0;

  ${respondTo.sm`
    text-decoration: none;
    margin-right: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0;
  `};

  span {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;

    ${respondTo.sm`
      font-size: 12px;
    `};
  }

  &.active,
  &:hover {
    span {
      border-bottom: 1px solid ${props => props.theme.primary};
    }
  }
`;

const NavigationItem = props => {
  const { link, children } = props;

  return (
    <li>
      <LinkElem exact to={link} {...props}>
        {children}
      </LinkElem>
    </li>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

export default NavigationItem;
